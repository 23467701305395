import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DatePicker } from '@material-ui/pickers';
import { getTime } from 'date-fns';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UsersStore } from '../../providers/users';
import { ORGANISATIONS_VIEW_ROUTE } from '../../routes/Types';
import { LAYOUT } from '../../utils/constants/layout.constants';
import {
  USER_ACCESS_LEVEL_OPTIONS,
  USER_LICENSE_OPTIONS,
  UserAccessLevel,
  UserLicense,
} from '../../utils/constants/userLicenses.constats';
import {
  ORGANISATION_ROLE_OPTIONS,
  UserOrganisationRole,
} from '../../utils/types/organisation';
import EditContentButtons from '../common/EditContentButtons';
import StyledInput from '../common/StyledInput';
import Title from '../common/Title';
import TopPannel from '../common/TopPannel';
import WrappedDialog from '../common/WrappedDialog';

interface UsersEditContentProps {}

export interface UsersFormFields {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  officeAddress: string;
  organization_role?: UserOrganisationRole;
  license_expires_at?: number;
  license?: UserLicense;
  access_level?: UserAccessLevel;
  disabled?: boolean;
  expiry?: Date;
}

interface ParamsType {
  id: string;
  orgId: string;
}

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 64,
    background: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
  title: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(5),
  },
  icon: {
    paddingRight: theme.spacing(2),
  },
  button: {
    height: 40,
    borderRadius: 10,
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  input: {
    'label + &': {
      marginTop: 0,
      color: theme.palette.text.secondary,
    },
  },
  textField: {
    width: '100%',
  },
  errorMessage: {
    height: theme.spacing(6),
  },
  propTitle: {
    fontWeight: 'bold',
    paddingRight: '8px',
  },
  select: {
    paddingLeft: 0,
  },
}));

const UsersEditContent: React.FC<UsersEditContentProps> = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const { id, orgId } = useParams<ParamsType>();
  const [updateStarted, setUpdateStarted] = useState(false);

  const [roleOptions, setRoleOptions] = useState(ORGANISATION_ROLE_OPTIONS);
  const [isRoleDisabled, setIsRoleDisabled] = useState(false);

  const [defaultValues, setDefaultValues] = useState<UsersFormFields>({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    officeAddress: '',
    organization_role: UserOrganisationRole.standard,
    license_expires_at: undefined,
    license: UserLicense.Go,
    access_level: UserAccessLevel.Client,
    disabled: false,
  });

  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const history = useHistory();

  const handleRedirectLinkClick = () => {
    history.push(`${ORGANISATIONS_VIEW_ROUTE.replace(':orgId', orgId)}`);
  };

  const {
    state: { updating, selectedUser },
    getUserDetails,
    updateUser,
  } = useContext(UsersStore);

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required('First name is required')
      .max(245, 'Must be no longer than 245 characters'),
    lastName: yup
      .string()
      .required('Last name is required')
      .max(245, 'Must be no longer than 245 characters'),
    company: yup
      .string()
      .required('Company name is required')
      .max(245, 'Must be no longer than 245 characters'),
    officeAddress: yup
      .string()
      .required('Office address is required')
      .max(245, 'Must be no longer than 245 characters'),
    organization_role: yup
      .string()
      .oneOf(['admin', 'standard', 'lite'])
      .required('Organisation Role is required'),
    license_expires_at: yup
      .date()
      .transform((value, originalValue) =>
        typeof originalValue === 'number' ? new Date(originalValue) : value
      )
      .typeError('Invalid date')
      .required('Date is required'),
    license: yup
      .string()
      .oneOf(['pro', 'go', 'all'])
      .required('License is required'),
    access_level: yup
      .string()
      .oneOf(['superadmin', 'editor', 'client'])
      .required('Access level is required'),
    disabled: yup.boolean().required('Disabled is required'),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm<UsersFormFields>({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const selectedLicense = watch('license');

  React.useEffect(() => {
    if (selectedLicense === UserLicense.Go) {
      setRoleOptions([{ name: 'Go', value: UserOrganisationRole.lite }]); // Only one option for 'Go' license
      setValue('organization_role', UserOrganisationRole.lite); // Set default value
      setIsRoleDisabled(true);
    } else if (selectedLicense === UserLicense.Pro) {
      setRoleOptions([
        { name: 'Admin', value: UserOrganisationRole.admin },
        { name: 'Pro', value: UserOrganisationRole.standard },
      ]); // Only two options for 'Pro' license
      setValue('organization_role', UserOrganisationRole.standard); // Set default value
      setIsRoleDisabled(false);
    } else {
      setRoleOptions(ORGANISATION_ROLE_OPTIONS); // Default options
      setIsRoleDisabled(false);
    }
  }, [selectedLicense, setValue]);

  const handleCreateUser = useCallback(
    async (data: UsersFormFields) => {
      if (selectedUser) {
        let license_expiry_miliseconds: number | undefined;

        if (data.license_expires_at) {
          license_expiry_miliseconds = Math.floor(
            getTime(new Date(data.license_expires_at))
          );
        }

        setUpdateStarted(true);
        await updateUser({
          id: selectedUser.id,
          ...data,
          organization_id: selectedUser.organization_id || 0,
          license_expires_at: license_expiry_miliseconds,
          type: selectedUser.type,
        });
      }
    },
    [selectedUser, updateUser]
  );

  useEffect(() => {
    getUserDetails(id);
  }, [id, getUserDetails]);

  useEffect(() => {
    if (selectedUser) {
      let license_expiry_miliseconds: number | undefined;

      if (selectedUser.license_expires_at) {
        license_expiry_miliseconds = selectedUser.license_expires_at;
      }

      const _defaultValues = {
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        email: selectedUser.email,
        company: selectedUser.company,
        officeAddress: selectedUser.officeAddress,
        organization_role: selectedUser.organization_role,
        license_expires_at: license_expiry_miliseconds,
        license: selectedUser.license,
        access_level: selectedUser.access_level as UserAccessLevel,
        disabled: selectedUser.disabled || false,
      };
      reset(_defaultValues);
      setDefaultValues(_defaultValues);
    }
  }, [id, selectedUser, reset]);

  const onDialogClose = useCallback(() => {
    setUpdateStarted(false);
  }, []);

  return (
    <Box className={classes.content}>
      {matchesMediaQuery ? (
        <TopPannel>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to organisation'}
          />
        </TopPannel>
      ) : (
        <Box textAlign="left" mr={4} ml={4}>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to organisation'}
          />
        </Box>
      )}
      <Box
        ml={{ xs: 4, sm: 4, md: 4, lg: 10 }}
        mr={{ xs: 4, sm: 4, md: 4, lg: 20 }}
      >
        <Typography
          variant="h4"
          color="textPrimary"
          align="left"
          classes={{ root: classes.title }}
        >
          Edit user
        </Typography>
        <Divider />
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={7}>
            <Box mt={8}>
              <Box>
                <StyledInput
                  name="firstName"
                  label="First name"
                  control={control}
                  error={!!errors.firstName}
                  classnames={{ formControl: classes.input }}
                />
                {errors.firstName && (
                  <Typography
                    variant="body1"
                    color="error"
                    className={classes.errorMessage}
                    align="left"
                  >
                    {errors.firstName.message}
                  </Typography>
                )}
              </Box>
              <Box mt={7}>
                <StyledInput
                  name="lastName"
                  label="Last name"
                  control={control}
                  error={!!errors.lastName}
                  classnames={{ formControl: classes.input }}
                />
                {errors.lastName && (
                  <Typography
                    variant="body1"
                    color="error"
                    className={classes.errorMessage}
                    align="left"
                  >
                    {errors.lastName.message}
                  </Typography>
                )}
              </Box>
              <Box mt={7}>
                <StyledInput
                  disabled={selectedUser?.type === 'user'}
                  name="email"
                  label="Email"
                  control={control}
                  error={!!errors.email}
                  classnames={{ formControl: classes.input }}
                />
              </Box>
              <Box mt={7}>
                <Typography variant="subtitle1" align="left">
                  License
                </Typography>
                <Controller
                  name={'license'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        onChange(e.target.value);
                      }}
                      className={classes.input}
                      classes={{ select: classes.select }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {USER_LICENSE_OPTIONS.map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Box>
              <Box mt={7}>
                <Typography variant="subtitle1" align="left">
                  Access Level
                </Typography>
                <Controller
                  name={'access_level'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        onChange(e.target.value);
                      }}
                      className={classes.input}
                      classes={{ select: classes.select }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {USER_ACCESS_LEVEL_OPTIONS.map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Box>
              <Box mt={7}>
                <StyledInput
                  name="company"
                  label="Company name"
                  control={control}
                  error={!!errors.company}
                  classnames={{ formControl: classes.input }}
                />
                {errors.company && (
                  <Typography
                    variant="body1"
                    color="error"
                    className={classes.errorMessage}
                    align="left"
                  >
                    {errors.company.message}
                  </Typography>
                )}
              </Box>
              <Box mt={7}>
                <StyledInput
                  name="officeAddress"
                  label="Office address"
                  control={control}
                  error={!!errors.officeAddress}
                  classnames={{ formControl: classes.input }}
                />
                {errors.officeAddress && (
                  <Typography
                    variant="body1"
                    color="error"
                    className={classes.errorMessage}
                    align="left"
                  >
                    {errors.officeAddress.message}
                  </Typography>
                )}
              </Box>
              <Box mt={7}>
                <Typography variant="subtitle1" align="left">
                  Organisation Role
                </Typography>
                <Controller
                  name={'organization_role'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        onChange(e.target.value);
                      }}
                      className={classes.input}
                      classes={{ select: classes.select }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      disabled={isRoleDisabled}
                    >
                      {roleOptions.map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Box>
              <Box mt={7} display={'flex'} flexDirection={'column'}>
                <Typography variant="subtitle1" align="left">
                  Subscription expires
                </Typography>
                <Controller
                  name="license_expires_at"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      value={value}
                      onChange={onChange}
                      InputProps={{ className: classes.input }}
                      renderInput={(props) => <TextField {...props} />}
                    />
                  )}
                />
                {errors.license_expires_at && (
                  <Typography
                    variant="body1"
                    color="error"
                    className={classes.errorMessage}
                    align="left"
                  >
                    {errors.license_expires_at?.message}
                  </Typography>
                )}
              </Box>
              <Box mt={7} display={'flex'}>
                <Controller
                  name="disabled"
                  control={control}
                  defaultValue={defaultValues.disabled}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value || false}
                          color="primary"
                        />
                      }
                      label="Disabled user"
                    />
                  )}
                />
              </Box>
            </Box>
            {selectedUser?.type === 'user' && (
              <Box mt={12}>
                <Box display={'flex'} alignItems="flex-start" mb={2}>
                  <Typography className={classes.propTitle}>
                    {'Job title: '}
                  </Typography>
                  <Typography align="left">
                    {selectedUser?.jobTitle || ''}
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems="flex-start" mb={2}>
                  <Typography className={classes.propTitle}>
                    {'Role: '}
                  </Typography>
                  <Typography align="left">
                    {selectedUser?.role || ''}
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems="flex-start" mb={2}>
                  <Typography className={classes.propTitle}>
                    {'Regions covered: '}
                  </Typography>
                  <Typography align="left">
                    {selectedUser?.regions || ''}
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems="flex-start" mb={2}>
                  <Typography className={classes.propTitle}>
                    {'Market themes: '}
                  </Typography>
                  <Typography align="left">
                    {selectedUser?.assetClasses || ''}
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems="flex-start" mb={2}>
                  <Typography className={classes.propTitle}>
                    {'Strategies: '}
                  </Typography>
                  <Typography align="left">
                    {selectedUser?.strategies || ''}
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems="flex-start" mb={2}>
                  <Typography className={classes.propTitle}>
                    {'Client types: '}
                  </Typography>
                  <Typography align="left">
                    {selectedUser?.clientTypes || ''}
                  </Typography>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Box mt={8} ml={{ xs: 4, sm: 4, md: 4, lg: 15 }}>
              <EditContentButtons
                updating={updating}
                handleRedirectLinkClick={handleRedirectLinkClick}
                handleSave={handleSubmit(handleCreateUser)}
              />
              <Box mt={5}>{updating && <CircularProgress />}</Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <WrappedDialog
        open={updateStarted && !updating}
        handleDialogClose={onDialogClose}
        dialogTitle={'Thank you'}
      >
        <DialogContent>
          <Box display="flex">
            <Typography>Your changes have been saved.</Typography>
            <Typography>An email is sent to the user.</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box mt={3} mb={3} mr={8}>
            <Button
              onClick={onDialogClose}
              color="secondary"
              variant="contained"
              disableElevation
              classes={{ root: classes.button }}
            >
              Close
            </Button>
          </Box>
        </DialogActions>
      </WrappedDialog>
    </Box>
  );
};

export default UsersEditContent;
